@import "./responsive.scss";

/* Required to fix dodgy table rendering with rounded borders and coloured cells */
.table-wrapper {
    position: relative;
    border: 1px solid var(--color-grey-200);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px -2px var(--color-shadow);
    width: 100%;
    overflow: hidden;
}

table {
    border-collapse: collapse;
    width: 100%;
    overflow: hidden;

    /* Tables with checkboxes in the 1st column have no right padding */
    &.has-checkboxes {
        th:first-child,
        td:first-child {
            padding-right: 0;
            width: calc(20px + (var(--base-spacing) * 3));
        }
    }
}

thead,
tfoot {
    background: var(--color-grey-50);
}

th {
    color: var(--color-grey-500);
    text-align: left;

    @extend .text-xs-medium;
}

th,
td {
    padding: calc(var(--base-spacing) * 1.5) calc(var(--base-spacing) * 3);
    vertical-align: middle;
}

/* Borders */
tr:not(:last-of-type),
thead tr {
    border-bottom: 1px solid var(--color-grey-200);
}

/* Border radius */
thead tr:first-child th:first-child {
    border-top-left-radius: var(--border-radius);
}
thead tr:first-child th:last-child {
    border-top-right-radius: var(--border-radius);
}

tbody tr:last-child td:first-child {
    border-bottom-left-radius: var(--border-radius);
}
tbody tr:last-child td:last-child {
    border-bottom-right-radius: var(--border-radius);
}

.pagination {
    @extend .grid, .row, .centered;
    justify-content: space-between;
    margin-right: 0;
    margin-left: auto;
    width: 330px;
}
