/* Match these in responsive.tsx */
$break-sm: 576px;
$break-md: 900px;
$break-lg: 1200px;

@media (max-width: $break-lg) {
    .hidden-md-down {
        display: none;
    }
}

@media (max-width: $break-md) {
    .hidden-sm-down {
        display: none;
    }
}

@media (max-width: $break-sm) {
    .hidden-xs-down {
        display: none;
    }
}

@media (min-width: $break-lg) {
    .hidden-lg-up {
        display: none;
    }
}

@media (min-width: $break-md) {
    .hidden-md-up {
        display: none;
    }
}

// Various UI elements must not shrink below x
// Forms and divs min 300px
div.min-width,
form.min-width {
    min-width: 300px;
}
