.grid {
    display: grid;
    gap: var(--base-spacing);
}

.column {
    grid-auto-flow: row;
    &.centered {
        justify-items: center;
    }
    &.self-centered {
        align-self: center;
    }
    &.space-between {
        align-content: space-between;
    }
    &.start {
        align-items: flex-start;
    }
}

.row {
    grid-auto-flow: column;
    &.centered {
        align-content: center;
        align-items: center;
    }
    &.self-centered {
        justify-self: center;
    }
    &.wrap {
        display: flex;
        flex-flow: wrap;
    }
    &.auto {
        display: grid;
        grid-auto-flow: unset;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
    &.space-between {
        justify-content: space-between;
    }
    &.start {
        justify-content: flex-start;
    }
}

form[class*="grid"],
.gap-md {
    gap: calc(var(--base-spacing) * 2);
}

.gap-lg {
    gap: calc(var(--base-spacing) * 4);
}

.fit-content {
    width: fit-content;
}
