@import "./reset.scss";
@import "./grid.scss";
@import "./typography.scss";
@import "./form.scss";
@import "./table.scss";
@import "./responsive.scss";
@import "./animations.scss";
@import "./alerts.scss";
@import "./auth-root.module.scss";

@font-face {
    font-family: "Inter", "Roboto", "arial", sans-serif;
    src: url("../../public/inter.ttf") format("ttf supports variations");
    font-weight: 100 1000;
}

:root {
    --color-ash: #1c1d1d;
    --color-ghostAsh: #232424;
    --color-grey-50: #f9fafb;
    --color-grey-100: #f2f4f7;
    --color-grey-200: #eaecf0;
    --color-grey-300: #d0d5dd;
    --color-grey-400: #98a2b3;
    --color-grey-500: #667085;
    --color-grey-900: #101828;

    --color-primary-800: #53389e;
    --color-primary-700: #6941c6;
    --color-primary-600: #7f56d9;
    --color-primary-500: #9400ff;
    --color-primary-400: #b692f6;
    --color-primary-200: #decaff;
    --color-primary-50: #f9f5ff;

    --color-primaryGreen: rgb(149, 250, 77);
    --color-transparent: rgba(0, 0, 0, 0);
    --color-white: #ffffff;
    --color-black: #000;
    --color-glow: rgba(105, 65, 198, 0.33);
    --color-shadow: rgba(16, 24, 40, 0.1);
    --color-background: var(--color-grey-300);
    --color-text: var(--color-grey-900);
    --color-card: var(--color-white);

    --color-success-700: #027a48;
    --color-success-500: #009f5d;
    --color-success-50: #ecfdf3;

    --color-warning-700: #9a4800;
    --color-warning-500: #f58600;
    --color-warning-50: #fdf6ec;

    --color-danger-700: #7a0202;
    --color-danger-500: #a60000;
    --color-danger-50: #fdecec;

    --color-bright-glass: rgba(255, 255, 255, 0.66);
    --color-light-glass: rgba(222, 202, 255, 0.66);
    --color-dark-glass: rgba(28, 29, 29, 0.4);

    --base-spacing: 8px;
    --border-radius: 8px;

    /* Chart colours */
    --color-chart-1: #7f56d9;
    --color-chart-2: #ee46bc;
    --color-chart-3: #0ba5ec;
    --color-chart-4: #26b29b;
    --color-chart-5: #ff9d24;
    --color-chart-6: #ce5454;
    --color-chart-7: #ad126c;
    --color-chart-8: #4169c6;
    --color-chart-9: #853da7;
    --color-chart-marker: #000000;
    --color-chart-marker-shadow: #ffffff;
}

body {
    @extend .text-sm-normal;
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text);
    background-color: var(--color-ghostAsh);
}

body,
#root {
    min-height: 100vh;
    min-height: 100dvh;
}

.card {
    padding: calc(var(--base-spacing) * 3);
    background: var(--color-card);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px 1px var(--color-shadow);
}

/* Scrollbar */
.thin-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        width: 6px;
        background-color: var(--color-primary-700);
        border-radius: 20px;
        &:hover {
            background-color: var(--color-primary-600);
        }
    }
}

/* Wrap the parent of any <Loader /> in order to position correctly when using `fillParent={true}` */
.loader-wrapper {
    position: relative;
}

.truncate {
    position: relative;
    mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 40px,
        rgba(0, 0, 0, 0) 60px
    );
    max-height: 70px;
}

hr {
    width: 100%;
    border: 0;
    border-top: var(--color-grey-200) 1px solid;
}
