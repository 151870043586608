a {
    text-decoration: none;
    color: var(--color-primary-700);
    transition: color 200ms ease-out;
    &:hover {
        color: var(--color-primary-800);
        text-decoration: underline;
    }
    outline-color: var(--color-primary-700);
}

/*
  These typographic styles are lifted straight from the Figma designs from Seesy.
*/

/* Largest text with semibold weight */
.text-xl-semibold {
    font-weight: 600;
    font-size: 30px;
}

/* Main headings, largest text with medium weight */
.text-xl-medium {
    font-weight: 500;
    font-size: 30px;
}

.text-lg-bold {
    font-size: 26px;
    font-weight: 800;
}

/* Secondary headings, large text */
.text-lg-medium {
    font-weight: 500;
    font-size: 18px;
}

.text-md-semibold {
    font-weight: 600;
    font-size: 16px;
}

/* Inputs, medium text */
.text-md-normal {
    font-weight: 400;
    font-size: 16px;
}

.text-sm-semibold {
    font-weight: 600;
    font-size: 14px;
}

/* Buttons text, "normal" text with medium weight */
.text-sm-medium {
    font-weight: 500;
    font-size: 14px;
}

/* Body text / Chart legend, "normal" text with normal weight */
.text-sm-normal {
    font-weight: 400;
    font-size: 14px;
}

/* Table headings, avatar labels, smallest text with medium weight */
.text-xs-medium {
    font-weight: 500;
    font-size: 12px;
}

/* Chart axis labels, smallest text with normal weight */
.text-xs-normal {
    font-weight: 400;
    font-size: 12px;
}

.text-white {
    color: var(--color-white);
    a {
        color: var(--color-primary-50);
        &:hover {
            color: var(--color-white);
            text-decoration: underline;
        }
    }
}

.text-grey-500 {
    color: var(--color-grey-500);
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-primary {
    color: var(--color-primary-700);
}

.preformatted {
    white-space: pre-wrap;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}
