/* Inputs / textareas / select dropdown */
input:not([type="checkbox"]),
input:not([type="radio"]),
input:not([type="submit"]),
select,
textarea {
    @extend .text-md-normal;
    box-sizing: border-box;
    width: 100%;
    padding: calc(var(--base-spacing) * 1.25) calc(var(--base-spacing) * 1.75);
    border: 1px solid var(--color-grey-300);
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    color: var(--color-grey-500);
    box-shadow: 0 1px 2px var(--color-shadow);
    outline-color: var(--color-primary-700);
    outline-width: 1px;

    &:focus {
        border-color: var(--color-primary-700);
        color: var(--color-ash);
    }

    &.large {
        padding: calc(var(--base-spacing) * 1.75)
            calc(var(--base-spacing) * 2.25);
        font-size: 1.1em;
        font-weight: bold;
    }
}

/* Buttons */
input[type="submit"],
.button,
button {
    -webkit-appearance: none;

    /* Base vars to override in variants - default is "primary" */
    --background-color: var(--color-primary-700);
    --background-hover-color: var(--color-primary-600);
    --background-focus-color: var(--color-primary-800);
    --text-color: var(--color-white);
    --text-hover-color: var(--color-white);
    --text-focus-color: var(--color-white);
    --border-color: var(--color-primary-700);
    --border-hover-color: var(--color-primary-700);
    --border-focus-color: var(--color-primary-800);

    @extend .text-sm-medium;
    display: grid;
    align-items: center;
    justify-items: flex-start;
    gap: calc(var(--base-spacing) * 1.5);
    padding: var(--base-spacing) calc(var(--base-spacing) * 1.5);
    color: var(--text-color);
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    transition: all 200ms ease-out;
    outline-color: var(--color-primary-700);
    outline-width: 1px;
    &:hover {
        background: var(--background-hover-color);
        border-color: var(--border-hover-color);
        color: var(--text-hover-color);
    }
    &:active,
    &:focus,
    &.active {
        background: var(--background-focus-color);
        border-color: var(--border-focus-color);
        color: var(--text-focus-color);
    }

    /* Variants */
    &.primary {
        --background-color: var(--color-primary-700);
        --background-hover-color: var(--color-primary-600);
        --text-color: var(--color-white);
        --text-hover-color: var(--color-white);
        --border-color: var(--color-primary-700);
        --border-hover-color: var(--color-primary-700);
    }
    &.primary-light {
        --background-color: var(--color-primary-50);
        --background-hover-color: var(--color-primary-50);
        --text-color: var(--color-primary-600);
        --text-hover-color: var(--color-primary-700);
        --border-color: var(--color-primary-50);
        --border-hover-color: var(--color-primary-600);
    }
    &.primary-alt {
        --background-color: var(--color-primary-500);
        --background-hover-color: var(--color-primary-600);
        --text-color: var(--color-white);
        --text-hover-color: var(--color-white);
        --border-color: var(--color-primary-500);
        --border-hover-color: var(--color-primary-600);
    }
    &.white {
        --background-color: var(--color-white);
        --background-hover-color: var(--color-primary-50);
        --text-color: var(--color-ash);
        --text-hover-color: var(--color-primary-700);
        --border-color: var(--color-grey-300);
        --border-hover-color: var(--color-primary-600);
    }
    &.transparent {
        --background-color: transparent;
        --background-hover-color: transparent;
        --background-focus-color: transparent;
        --text-color: var(--color-grey-400);
        --text-hover-color: var(--color-ash);
        --text-focus-color: var(--color-primary-600);
        --border-color: transparent;
        --border-hover-color: transparent;
        --border-focus-color: transparent;
        padding: var(--base-spacing);
    }
    &.has-label {
        grid-template-columns: auto 1fr;
        width: fit-content;
    }
    &.is-square {
        width: 48px;
        height: 48px;
        justify-items: center;
        aspect-ratio: 1/1;
    }
    &.is-outlined {
        --background-color: transparent;
    }
    &.large {
        padding: calc(var(--base-spacing) * 2.25);
        font-size: 1.1em;
        font-weight: 700;
        border-width: 2px;
    }
    &:not(:disabled) {
        cursor: pointer;
    }
    &:disabled {
        opacity: 0.66;
        cursor: not-allowed;
    }
    &[data-is-success="true"] {
        --background-color: var(--color-success-700);
        --background-hover-color: var(--color-success-500);
        --background-focus-color: var(--color-success-700);
        --border-color: var(--color-success-700);
        --border-hover-color: var(--color-success-500);
        --border-focus-color: var(--color-success-700);
    }
}

/* Checkboxes */
input[type="checkbox"]:not(.toggle) {
    position: absolute;
    width: 20px;
    height: 20px;
    opacity: 0;

    + label {
        display: grid;
        place-content: center;
        width: 20px;
        height: 20px;
        font-size: 12px;
        color: var(--color-grey-300);
        background-color: var(--color-white);
        border-width: 1px;
        border-style: solid;
        border-color: var(--color-grey-300);
        border-radius: calc(var(--border-radius) * 0.75);
        cursor: pointer;
        transition: all 200ms ease-out;
    }
    &:hover {
        + label {
            border-color: var(--color-primary-600);
        }
    }

    &:checked {
        + label {
            color: var(--color-primary-600);
            background-color: var(--color-primary-50);
            border-color: var(--color-primary-600);
        }
    }

    /* Accessibility - focus/active events mainly for keyboard nav */
    &:focus,
    &:active {
        + label {
            box-shadow: 0 0 0px 2px var(--color-primary-600);
        }
    }
}

/* Toggle */
input.toggle[type="checkbox"] {
    position: absolute;
    width: 50px;
    opacity: 0;
    cursor: pointer;

    + label {
        display: grid;
        width: 50px;
        padding: 2px;
        background-color: var(--color-grey-200);
        border-width: 1px;
        border-style: solid;
        border-color: var(--color-grey-300);
        border-radius: 9999px;
        transition: all 200ms ease-out;
        cursor: pointer;

        &:after {
            content: " ";
            width: 22px;
            height: 22px;
            left: 0;
            background-color: var(--color-white);
            border-width: 1px;
            border-style: solid;
            border-color: var(--color-grey-300);
            border-radius: 9999px;
            transition: all 200ms ease-out;
            cursor: pointer;
        }
    }

    &:hover,
    &:focus,
    &:active {
        + label {
            background-color: var(--color-primary-50);
            border-color: var(--color-primary-700);
            box-shadow: 0 0 0px 1px var(--color-primary-700);
        }
    }

    &:checked {
        + label {
            background-color: var(--color-primary-700);
            border-color: var(--color-primary-700);
            &:after {
                transform: translateX(calc(100% - 4px));
                border-color: var(--color-primary-700);
            }
        }
        &:hover,
        &:focus,
        &:active {
            + label {
                background-color: var(--color-primary-600);
                border-color: var(--color-primary-600);
                &:after {
                    border-color: var(--color-primary-600);
                }
            }
        }
    }
}

.form-group {
    display: grid;
    gap: calc(var(--base-spacing) / 2);
}
