.animated {
    &.load {
        animation-name: load;
        animation-duration: 500ms;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
    }
    &.spin {
        animation-name: spin;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    &.pulse {
        animation-name: pulse;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;
    }
    &.gradient-background {
        background: radial-gradient(circle at bottom, #250140 20%, #1c1d1d 66%);
        background-size: 200vw 200vh;
        animation-name: gradient-background;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
    }
}

@keyframes load {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    0% {
        opacity: 1;
    }
}

@keyframes gradient-background {
    0% {
        background-position: 50% 100%;
    }
    50% {
        background-position: 50% 0%;
    }
    100% {
        background-position: 50% 100%;
    }
}
