.alerts-container {
    @extend .grid, .column;
    position: fixed;
    bottom: 20px;
    right: 20px;
    overflow: visible;
    z-index: 99999;

    div {
        @extend .grid, .row, .centered, .gap-md;
        justify-content: flex-start;
        padding: calc(var(--base-spacing) * 2);
        background: var(--color-primary-700);
        box-shadow: 0 0 2px var(--color-shadow);
        backdrop-filter: blur(5px);
        border-radius: var(--border-radius);
        color: var(--color-white);

        max-width: calc(100vw - (calc(var(--base-spacing) * 4)));

        @media (min-width: $break-sm) {
            max-width: 50vw;
        }
        @media (min-width: $break-md) {
            max-width: 33vw;
        }
    }
}
