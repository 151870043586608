/*
 Overrides for pages using AuthRoot
 More efficient and easier to override than setting text-white etc everywhere
 Modules seem to not work since I'm not using classes, I'll keep the name anyway for clarity of intention
 If I @import this in AuthRoot itself, the style changes apply globally
 Might refactor later, sorry, I'm not used to doing things "the React way". 
 I tend to lean overriding base elements where possible for easier markup since I tend to use Angular, Vue and Svelte over React which automatically scopes all CSS to components, even vanilla elements
*/
.auth-root {
    --color-text: var(--color-white);
    --color-danger-700: #fdecec;
    --color-danger-50: #4a0000;

    color: var(--color-text);

    a {
        color: var(--color-primary-50);
        &:hover {
            color: var(--color-white);
        }
    }

    .text-primary {
        color: var(--color-primary-50);
    }

    section {
        width: min(80vw, 350px);
        header {
            text-align: center;
        }
    }

    a {
        color: var(--color-primary-200);
        font-weight: 600;
        &:hover {
            color: var(--color-white);
        }
    }

    label {
        font-weight: 700;
    }
}
